import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import InjectAxiosInterceptors from './services/InjectAxiosInterceptor';
import ProgressBar from './components/ProgressBar/ProgressBar';

const Checkout = lazy(() => import('./pages/Checkout/Checkout'));
const NoMatchPage = lazy(() => import('./pages/404'));
const Success = lazy(() => import('./pages/Success'));
const Error = lazy(() => import('./pages/Error'));

function Loader(props) {
  return (
    <Suspense
      {...props}
      fallback={
        <ProgressBar
          classes={{
            root: { position: 'absolute', top: 0, left: 0 },
          }}
          size="sm"
          color="secondary"
        />
      }
    />
  );
}

function App() {
  return (
    <Router>
      <InjectAxiosInterceptors />
      <Loader>
        <Routes>
          <Route path="/checkout/:id" element={<Checkout />} />
          <Route path="/success" element={<Success />} />
          <Route path="/error" element={<Error />} />
          <Route path="*" element={<NoMatchPage />} />
        </Routes>
      </Loader>
    </Router>
  );
}

export default App;
